<template>
  <div class="custom-card fd-bg-white fd-px-4 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('onlineSetting.onlineSetting') }}</span>
    </div>
    <span class="fd-block fd-mt-8 fd-mb-3 fd-text-lg fd-font-bold fd-text-theme-6">{{ $t('onlineSetting.branding') }}</span>
    <div class="fd-w-full fd-px-3 fd-flex fd-items-end fd-mt-4 fd-border-b fd-pb-8">
      <div class="fd-w-1/3">
        <div
          @click="selectFile"
          class="fd-relative fd-cursor-pointer fd-w-24 fd-h-24 fd-rounded-lg fd-overflow-hidden"
          style="border: 1px solid #E8E8E8;"
        >
          <img v-if="getNewImageUrl || thumbnail" class="fd-w-full fd-h-full" :src="getNewImageUrl ? getNewImageUrl : thumbnail" alt="avatar">
          <span v-else class="fd-block fd-w-full fd-h-full fd-bg-gray-400"></span>
          <span class="fd-block fd-w-full fd-h-full fd-absolute fd-left-0 fd-top-0 fd-bg-white fd-opacity-70"></span>
          <SvgLoader name="exportIcon2" class="fd-absolute" style="left: 50%; top: 50%; transform: translate(-50%, -50%)" />
        </div>
      </div>

      <div class="fd-w-2/3 fd-flex fd-items-start fd-justify-start">
        <input type="file" ref="file" @change="changeFile" style="display: none">
        <b-button @click="upload" variant="primary" class="fd-mr-4 btn-custom">{{ $t('global.upload') }}</b-button>
        <b-button @click="resetFile" variant="outline-primary" class="btn-custom">{{ $t('global.reset') }}</b-button>
      </div>
    </div>
    <span class="fd-block fd-mt-8 fd-mb-3 fd-text-lg fd-font-bold fd-text-theme-6">{{ $t('onlineSetting.socialNetwork') }}</span>
    <div class="fd-w-full fd-px-3 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label for="instagram" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>{{ $t('onlineSetting.instagram') }}</span>
      </label>
      <div class="fd-w-2/3">
        <b-form-input
          id="instagram"
          v-model="form.instagram"
          :placeholder="$t('onlineSetting.inputSocialNetwork')"
        />
      </div>
    </div>
    <div class="fd-w-full fd-px-3 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label for="facebook" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>{{ $t('onlineSetting.facebook') }}</span>
      </label>
      <div class="fd-w-2/3">
        <b-form-input
          id="facebook"
          v-model="form.facebook"
          :placeholder="$t('onlineSetting.inputSocialNetwork')"
        />
      </div>
    </div>
    <div class="fd-w-full fd-px-3 fd-py-5 fd-flex fd-items-center fd-justify-between fd-border-b fd-pb-8">
      <label for="linkedin" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>{{ $t('onlineSetting.linkedin') }}</span>
      </label>
      <div class="fd-w-2/3">
        <b-form-input
          id="linkedin"
          v-model="form.linkedin"
          :placeholder="$t('onlineSetting.inputSocialNetwork')"
        />
      </div>
    </div>
    <div>
      <span class="fd-block fd-mt-8 fd-mb-3 fd-text-lg fd-font-bold fd-text-theme-6">{{ $t('onlineSetting.customDomain') }}</span>
      <div class="fd-w-full fd-px-3 fd-py-5 fd-flex fd-items-center fd-justify-between fd-pb-8">
        <label for="domain" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('onlineSetting.domain') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="domain"
            v-model="form.domain"
            :placeholder="$t('onlineSetting.domain')"
          />
        </div>
      </div>
    </div>
    <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-8 fd-pr-3">
      <b-button
        @click="save"
        variant="primary"
        style="padding-left: 35px !important; padding-right: 35px !important;"
      >
        {{ $t('global.save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton } from 'bootstrap-vue'

export default {
  name: "Branding",
  components: {BFormInput, BButton},
  data() {
    return {
      form: {
        instagram: '',
        facebook: '',
        linkedin: '',
        domain: '',
      },
      image: null,
      newImageUrl: null,
      thumbnail: ''
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
  },
  computed: {
    getNewImageUrl() {
      return this.newImageUrl
    }
  },
  methods: {
    save() {
      this.$store.dispatch('project/updateProject', {
        id: this.$route.params.id,
        data: this.form
      })
    },
    selectFile() {
      this.$refs.file.click()
    },
    changeFile() {
      this.image = this.$refs.file.files[0]
      this.newImageUrl = URL.createObjectURL(this.image)
      this.$refs.file.value = ''
    },
    resetFile() {
      this.image = null
      this.newImageUrl = null
    },
    upload() {
      if (!this.newImageUrl) {
        this.selectFile()
        return
      }
      this.$store.dispatch('project/updateProject', {
        id: this.$route.params.id,
        data: {
          file: this.newImageUrl
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-card {
  box-shadow: 0 4px 24px rgba(40, 74, 51, 0.06);
  border-radius: 6px;
}
</style>